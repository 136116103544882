@use '@angular/material' as mat;
@use 'palette' as hue;
@use 'variables';

@import "imports";

button {
	text-transform: uppercase;
	font-weight: 600 !important;
	border-radius: 4px !important;
}

html,
body {
	font-family: 'Roboto', sans-serif;
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
}

a,
a:visited {
	color: mat.get-color-from-palette(hue.$material-blue-grey, 800);
}

.position-relative {
	position: relative;
}

.navigate-bar {
	@include mobile-center();
	margin-top: 10px;
	margin-bottom: 10px;
}

button.icon-inside {
	text-align: center;

	fa-icon {
		font-size: 1.1rem;
		margin-right: .5em;
	}
}

mat-hint.icon-inside {
	fa-icon {
		margin-right: .5em;
	}
}

.action-icon {
	font-size: 1.2rem;

	&:hover {
		cursor: pointer;
	}

	&.disabled {
		opacity: 0.6;
		&:hover {
			cursor: not-allowed;
		}
	}
}

.alert-danger {
	color: $col-danger;
	background-color: lighten($col-danger, 65%);
	border-color: lighten($col-danger, 30%);
}

.alert-dismissible {
	padding-right: 4rem;
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem;
}

.alert-success {
	color: $col-success;
	background-color: lighten($col-success, 65%);
	border-color: lighten($col-success, 30%);
}

.mat-checkbox-layout {
	white-space: normal !important;
}

ngx-material-timepicker-toggle svg {
	@include size(1.2rem);
	fill: $text-muted;
}

.mat-button-toggle {

	button {
		@extend .mat-flat-button;
	}

	&.mat-button-toggle-checked button {
		@extend .mat-primary;
	}
}

button[mat-mini-fab] .mat-button-wrapper {
	padding-top: .2rem;
}

.hidden-file-input {
	display: none;
}


.success {
	color: $col-success !important;
}
