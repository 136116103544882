@use '@angular/material' as mat;
@use 'palette' as hue;

.status-badge {
	position: absolute;
	right: 15px;
	top: 15px;
	z-index: 1000;
	text-transform: uppercase;
	background-color: #ccc;
	border-radius: 15px 15px;
	text-align: center;
	width: auto;
	padding: 5px;
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 1px;

	&.overdue {
		color: white;
		background-color: mat.get-color-from-palette(hue.$material-red, 600);
	}

	&.pending {
		color: white;
		background-color: mat.get-color-from-palette(hue.$material-indigo, 600);
	}

	&.awaiting-config {
		background-color: mat.get-color-from-palette(hue.$material-amber, 600);
	}

	&.pre-order {
		background-color: mat.get-color-from-palette(hue.$material-yellow, 600);
	}

	&.relocation {
		background-color: mat.get-color-from-palette(hue.$material-blue, 600);
	}

	&.completed {
		background-color: mat.get-color-from-palette(hue.$material-green, 600);
	}

	&.cancelled {
		color: white;
		background-color: mat.get-color-from-palette(hue.$material-grey, 600);
	}

	&.missing {
		background-color: mat.get-color-from-palette(hue.$material-red, 600);
	}

	&.in-progress {
		color: white;
		background-color: mat.get-color-from-palette(hue.$material-green, 600);
	}
}
