.mat-table {
	display: block;
}

.mat-row,
.mat-header-row {
	display: flex;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	align-items: center;
	min-height: 48px;
	padding: 0 24px;
}

.mat-cell,
.mat-header-cell {
	flex: 1;
	overflow: hidden;
	word-wrap: break-word;
}
